<template>
  <div id="app">
    <div class="row">
      <div class="col-md-12 text-start">
        <h5>
          <a
            class="btn btn-sm btn-outline-secondary"
            @click="$router.push('/crud/cabinetbelow')"
          >
            <i class="fa fa-arrow-left"> </i> </a
          ><strong> Tipos de Gabinete Inferior - Agregar</strong>
        </h5>
        <hr />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4 text-start">
        <div class="form-group">
          <label>ID</label>
          <input
            type="number"
            v-model="model.id"
            reaDonly
            class="form-control"
          />
        </div>
        <div class="form-group mt-2">
          <label>Descripción</label>
          <input
            type="text"
            v-model="model.description"
            class="form-control"
            placeholder="Descripción"
          />
        </div>
        <div class="mt-2 text-end row">
          <div class="col-2">
            <button
              type="submit"
              @click="$router.push('/crud/cabinetbelow')"
              class="btn btn-secondary mt-3 mr-2"
            >
              Cancelar
            </button>
          </div>
          <div class="col-2">
            <button type="submit" class="btn btn-primary mt-3">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import api from "@/services/DataCruds/ConstructionSystem";

export default {
  components: {},
  data() {
    return {
      dataList: [],
      model: { id: null, description: null },
    };
  },
  async mounted() {},
  props: {},
  methods: {},
};
</script>
